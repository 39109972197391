import { ReactElement, useState, ChangeEvent } from 'react'
import { useLocalisation } from '../../lib/i18n'
import { set } from '../../lib/query'
import { t, Trans } from '@lingui/macro'

import { AlignJustify, ThLarge, ThList } from '@discogs/amped/icons'
import { AngleDown, AngleUp, AngleLeft, AngleRight } from '../../lib/components/icon'
import css from './styles.css'
import { UIProps } from '../artist-discography'
import { ArtistPaginationInfo, OrderByDirection } from '../../api/types'

import { FilterButton } from '../../lib/components/filterButton/filterButton'
import { FacetFilter, FacetObjects } from '../../lib/components/facetFilters'
import { FacetFilterMobile } from '../../lib/components/facetFilters/mobileUI'
import { useHistory, useLocation } from 'react-router'
import { removeTypename } from '../../lib/remove-typename'

type Props = {
    dispatch: UIProps['dispatch']
    order: OrderByDirection
    page: number
    perPage: number
    format: FacetObjects | null
    label: FacetObjects | null
    country: FacetObjects | null
    year: FacetObjects | null
    labelSelected: string[] | []
    countrySelected: string[] | []
    formatSelected: string[] | []
    yearSelected: number[] | []
    advancedFilterSelected: { [key: string]: boolean }
    view: 'textWithCovers' | 'coversOnly' | 'textOnly'
    itemsOnPage: number | undefined
    totalCounts: number | null
    loading: boolean
    filterSelected: string[]
    toggleSidebar: () => void
    creditName: string
    tab: string
    setTab: (value: string) => void
    headerIsExpanded: boolean
    search: string
    advancedFilterShowState: {
        desktop: boolean | null
        mobile: boolean
    }
    paginationInfo: ArtistPaginationInfo['artistPages']
}

export function ArtistFiltersUI(props: Props): ReactElement {
    const {
        dispatch,
        order,
        page,
        perPage,
        paginationInfo,
        format,
        label,
        year,
        labelSelected,
        formatSelected,
        countrySelected,
        yearSelected,
        country,
        advancedFilterSelected,
        view,
        itemsOnPage,
        totalCounts,
        loading,
        filterSelected,
        toggleSidebar,
        creditName,
        tab,
        setTab,
        headerIsExpanded,
        search,
        advancedFilterShowState,
    } = props

    const facetsObject = {
        format,
        label,
        country,
        year,
    }

    return (
        <div>
            <div className={css.releasesPath}>
                {filterSelected[1] ? (
                    <span>
                        <button
                            onClick={() =>
                                dispatch({
                                    type: 'sidebarFilters',
                                    filterSelected: [filterSelected[0]],
                                    initialFilter: [''],
                                })
                            }
                        >
                            <Trans>{filterSelected[0]}</Trans>
                        </button>
                        <AngleRight aria-hidden='true' />
                        <Trans>
                            <h2>{filterSelected[1]}</h2>
                        </Trans>
                    </span>
                ) : creditName ? (
                    <span>
                        <Trans>
                            <h2>Credits</h2>
                        </Trans>
                        <AngleRight aria-hidden='true' />
                        <Trans>
                            <h2 className={css.middleChild}>{filterSelected[0]}</h2>
                        </Trans>
                        <AngleRight aria-hidden='true' />
                        <Trans>
                            <h2>{creditName}</h2>
                        </Trans>
                    </span>
                ) : ['Releases', 'Appearances', 'Unofficial', 'Credits'].includes(filterSelected[0]) ? (
                    <h2>{filterSelected[0]}</h2>
                ) : (
                    <span>
                        <Trans>
                            <h2>Credits</h2>
                        </Trans>{' '}
                        <AngleRight aria-hidden='true' />{' '}
                        <Trans>
                            <h2>{filterSelected[0]}</h2>
                        </Trans>
                    </span>
                )}
            </div>

            {advancedFilterShowState.desktop && (
                <FacetFilter
                    data={facetsObject}
                    // TODO return to type this correctly - Inside the filters we are narrowly
                    // typing this to correspond to the actions we use but outside it is typed
                    // to accept all the actions we use.
                    // @ts-expect-error
                    dispatch={dispatch}
                    labelSelected={labelSelected}
                    formatSelected={formatSelected}
                    countrySelected={countrySelected}
                    yearSelected={yearSelected}
                    advancedFilterSelected={advancedFilterSelected}
                    advancedFilterShowState={advancedFilterShowState}
                    loading={loading}
                    search={search}
                    inputValues={['Find a format', 'Find a label or company', 'Find a country', 'Find a year']}
                />
            )}
            <FacetFilterMobile
                data={facetsObject}
                // TODO return to type this correctly - Inside the filters we are narrowly
                // typing this to correspond to the actions we use but outside it is typed
                // to accept all the actions we use.
                // @ts-expect-error
                dispatch={dispatch}
                labelSelected={labelSelected}
                formatSelected={formatSelected}
                countrySelected={countrySelected}
                yearSelected={yearSelected}
                advancedFilterSelected={advancedFilterSelected}
                advancedFilterShowState={advancedFilterShowState}
                order={order}
                page={page}
                perPage={perPage}
                format={format}
                label={label}
                country={country}
                year={year}
                view={view}
                itemsOnPage={itemsOnPage}
                totalCounts={totalCounts}
                loading={loading}
                filterSelected={filterSelected}
                toggleSidebar={toggleSidebar}
                creditName={creditName}
                tab={tab}
                setTab={setTab}
                headerIsExpanded={headerIsExpanded}
                search={search}
                inputValues={['Find a format', 'Find a label or company', 'Find a country', 'Find a year']}
            />
            <div className={css.filterBarContainerDesktop}>
                <div className={css.one}>
                    <Count perPage={perPage} page={page} itemsOnPage={itemsOnPage} totalCounts={totalCounts} />
                    <Pagination
                        dispatch={dispatch}
                        page={page}
                        totalCounts={totalCounts}
                        perPage={perPage}
                        itemsOnPage={itemsOnPage}
                        paginationInfo={paginationInfo}
                    />
                </div>
                <div className={css.two}>
                    <FilterButton
                        loading={loading}
                        advancedFilterSelected={advancedFilterSelected}
                        advancedFilterShowState={advancedFilterShowState}
                        dispatch={dispatch}
                    />
                    <span className={css.divider} />
                    <Sort order={order} dispatch={dispatch} perPage={perPage} />
                    <View dispatch={dispatch} view={view} />
                    <Show dispatch={dispatch} perPage={perPage} />
                </div>
                <div className={css.twoMobile}>
                    <Sort order={order} dispatch={dispatch} perPage={perPage} />
                    <View dispatch={dispatch} view={view} />
                </div>
            </div>
        </div>
    )
}

type CountProps = {
    page: number
    perPage: number
    itemsOnPage: number | undefined
    totalCounts: number | null
}

export function Count(props: CountProps): ReactElement {
    const { perPage, page, itemsOnPage, totalCounts } = props

    const adjustedCount =
        totalCounts && totalCounts < perPage && Math.min(totalCounts, page * perPage) !== itemsOnPage
            ? itemsOnPage
            : totalCounts && Math.min(totalCounts, page * perPage)

    const adjustedTotalCount =
        totalCounts && totalCounts < perPage && adjustedCount !== totalCounts ? adjustedCount : totalCounts

    if (!itemsOnPage && !totalCounts) {
        return (
            <div className={css.countContainer}>
                <Trans>
                    <p>Showing 0 - 0 of 0</p>
                </Trans>
            </div>
        )
    }

    if (itemsOnPage && itemsOnPage < perPage) {
        return (
            <div className={css.countContainer}>
                <p>
                    <Trans>
                        Showing {(page - 1) * perPage + 1}-{itemsOnPage + (page - 1) * perPage} of{' '}
                        {itemsOnPage && itemsOnPage + (page - 1) * perPage}
                    </Trans>
                </p>
            </div>
        )
    }

    return (
        <div className={css.countContainer}>
            <p>
                <Trans>
                    Showing {(page - 1) * perPage + 1}-{adjustedCount} of {adjustedTotalCount}
                </Trans>
            </p>
        </div>
    )
}

type PaginationProps = {
    dispatch: UIProps['dispatch']
    page: number
    perPage: number
    totalCounts: number | null
    itemsOnPage: number | undefined
    paginationInfo: ArtistPaginationInfo['artistPages']
}

export function Pagination(props: PaginationProps): ReactElement | null {
    const { dispatch, page, perPage, totalCounts, itemsOnPage, paginationInfo } = props
    const location = useLocation()
    const history = useHistory()
    if (!totalCounts) {
        return (
            <div className={css.paginationContainer}>
                <button disabled>
                    <AngleLeft /> <Trans>Prev</Trans>
                </button>
                <button disabled>
                    <Trans>Next</Trans> <AngleRight />
                </button>
            </div>
        )
    }

    const cleanedData = removeTypename(paginationInfo)

    const pages = Math.ceil(totalCounts / perPage)

    function next() {
        dispatch({ type: 'pagination', page: page + 1, perPage, artistPages: cleanedData })
        history.push(
            set(location, {
                page: page + 1,
            }),
        )
    }

    function prev() {
        const poppedData = [...cleanedData]
        poppedData.pop()
        dispatch({ type: 'pagination', page: page - 1, perPage, artistPages: poppedData })
        if (page - 1 === 1) {
            history.push(
                set(location, {
                    page: null,
                }),
            )
        } else {
            history.push(
                set(location, {
                    page: page - 1,
                }),
            )
        }
    }

    // because we are not displaying releases where an artist is credited more then once as seperate releases,
    // and the api does not have a record of how many releases a user is credited on (only how many credits they have)
    // we need to tell the pagination buttons to not allow the user to continue onto the next set of releases if the
    // actual count of releases is less then the totalCount of credits.

    // if the number of items on the page is less then the show # we are on the last page
    // do not display a forward button.
    const displayLeft = page === 1 || totalCounts <= perPage
    const displayRight = page === pages || totalCounts <= perPage || (itemsOnPage && itemsOnPage < perPage)

    return (
        <div className={css.paginationContainer}>
            <button className={displayLeft ? css.displayButton : ''} onClick={prev}>
                <AngleLeft aria-hidden='true' /> <Trans>Prev</Trans>
            </button>
            <button className={displayRight ? css.displayButton : ''} onClick={next}>
                <Trans>Next</Trans> <AngleRight aria-hidden='true' />
            </button>
        </div>
    )
}
export type SortProps = {
    dispatch: UIProps['dispatch']
    order: OrderByDirection
    perPage: number
}
export function Sort(props: SortProps): ReactElement {
    const { order, dispatch, perPage } = props
    const location = useLocation()
    const history = useHistory()

    const [arrow, setArrow] = useState(order === 'ASC')
    const sortOrder = order === 'ASC' ? 'DESC' : 'ASC'

    function clickHandler() {
        setArrow(!arrow)
        dispatch({ type: 'sortBy', order: sortOrder })
        dispatch({ type: 'pagination', page: 1, perPage, artistPages: [] })
        history.push(
            set(location, {
                page: null,
            }),
        )
    }

    return (
        <div className={css.sortContainer}>
            <button onClick={() => clickHandler()}>
                <p>
                    <Trans>Year</Trans>
                </p>
                {arrow ? <AngleUp aria-hidden='true' /> : <AngleDown aria-hidden='true' />}
            </button>
        </div>
    )
}

type ViewProps = {
    dispatch: UIProps['dispatch']
    view: 'textWithCovers' | 'coversOnly' | 'textOnly'
}

function View(props: ViewProps): ReactElement {
    const { dispatch, view } = props
    const { i18n } = useLocalisation()

    function clickHandler(viewType: 'textWithCovers' | 'coversOnly' | 'textOnly'): void {
        dispatch({ type: 'view', view: viewType })
    }

    return (
        <div className={css.viewContainer} role='radiogroup'>
            <button
                type='button'
                role='radio'
                aria-checked={view === 'coversOnly'}
                onClick={() => clickHandler('coversOnly')}
                title={i18n._(`Switch to covers only view`)}
            >
                <ThLarge aria-hidden='true' />
            </button>
            <button
                type='button'
                role='radio'
                aria-checked={view === 'textWithCovers'}
                onClick={() => clickHandler('textWithCovers')}
                title={i18n._(`Switch to text with covers view`)}
            >
                <ThList aria-hidden='true' />
            </button>
            <button
                type='button'
                role='radio'
                aria-checked={view === 'textOnly'}
                onClick={() => clickHandler('textOnly')}
                title={i18n._(t`Switch to text-only view`)}
            >
                <AlignJustify aria-hidden='true' />
            </button>
        </div>
    )
}
type ShowProps = {
    dispatch: UIProps['dispatch']
    perPage: number
}
export function Show(props: ShowProps): ReactElement {
    const { dispatch, perPage } = props
    const location = useLocation()
    const history = useHistory()

    function changeHandler(e: ChangeEvent<HTMLSelectElement>): void {
        dispatch({ type: 'pagination', page: 1, perPage: parseInt(e.target.value, 10), artistPages: [] })
        history.push(
            set(location, {
                page: null,
            }),
        )
    }

    return (
        <div className={css.showContainer}>
            <label htmlFor='show'>Show</label>
            <div className={css.selectContainer}>
                <select
                    name='show'
                    id='show'
                    value={typeof perPage === 'number' ? perPage : 25}
                    onChange={(e) => changeHandler(e)}
                >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={250}>250</option>
                    <option value={500}>500</option>
                </select>
            </div>
        </div>
    )
}

type TabsProps = {
    tab: string
    setTab: (value: string) => void
}

export function TabsMobile(props: TabsProps): ReactElement {
    const { tab, setTab } = props

    const values = ['Discography', 'Reviews', 'Videos', 'Lists']

    function clickHandler(value: string) {
        setTab(value)
    }

    return (
        <div className={css.tabsContainerMobile}>
            <div className={css.innerContainer}>
                {values.map((value) => (
                    <button
                        role='radio'
                        onClick={() => clickHandler(value)}
                        className={css.selection}
                        key={value}
                        aria-checked={tab === value}
                    >
                        <Trans>{value}</Trans>
                    </button>
                ))}
            </div>
        </div>
    )
}
