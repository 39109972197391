import { ReactElement } from 'react'

import { LabelReleaseGroupReleasesQuery, Release } from '../../api/types'

import { Loading } from '../../lib/components/loading'

import { Version } from '../artist-views/ui'

import css from './styles.css'

type VersionsProps = {
    view: string
    data: LabelReleaseGroupReleasesQuery | undefined
    labelLoading: boolean
    labelCatalogNumber: string | undefined
}

export function LabelVersions(props: VersionsProps): ReactElement | null {
    const { view, data, labelLoading, labelCatalogNumber } = props

    if (!data || labelLoading) {
        return (
            <tr>
                <td className={css.versionsLoadingSpinner}>
                    <Loading height={100} />
                </td>
            </tr>
        )
    }

    const versions = data.label?.releases.releaseGroupDetails?.map((individualRelease) => individualRelease)

    return (
        <>
            {versions &&
                // @ts-expect-error - TODO return to type this correctly
                versions.map((version: Partial<Release> | undefined) => (
                    <Version
                        version={version}
                        key={version?.discogsId}
                        view={view}
                        collectionItems={version?.collectionItems}
                        labelCatalogNumber={labelCatalogNumber}
                    />
                ))}
        </>
    )
}
