/* eslint-disable complexity */
import { ReactElement, Fragment, useRef, useEffect, useState } from 'react'
import { Trans, t, plural } from '@lingui/macro'
import { AngleDown, ZoomIn, AngleUp, EllipsisH } from '../../lib/components/icon'
import { dimensions } from '../../lib/components/picture'

import { useInteraction } from '../../lib/components/analytics'
import {
    ReleaseLabelRole,
    ItemType,
    ArtistReleaseGroupDescription,
    Format,
    LabelRelationship,
    Release as TSRelease,
    useArtistReleasesDataQuery,
    CollectionItemConnection,
    PrimaryArtist,
    CollectionItem,
    CollectionItemEdge,
    MasterRelease,
    WantlistItem,
    Release,
    useLabelReleaseGroupReleasesLazyQuery,
} from '../../api/types'

import { intersperse } from '../../lib/intersperse'
import { year } from '../../lib/date'
import { useClickOutside } from '../../lib/use-click-outside'
import { Link } from '../../lib/components/link'
import { Skittle } from '../../lib/components/skittle'
import { ReleaseLabelLink } from '../release-label-link'

import css from './styles.css'
import { VersionEdge } from '../version-finder'
import { Icon } from '../release-thumbnail/icon'
import { asFormat } from '../../lib/format'
import { useLocalisation } from '../../lib/i18n'

import { Loading } from '../../lib/components/loading'

import { useAddReleaseToCollectionMutation } from '../../mutations/add-release-to-collection'
import { useAddReleasesToWantlistMutation } from '../../mutations/add-releases-to-wantlist'
import { useRemoveReleasesFromWantlistMutation } from '../../mutations/remove-releases-from-wantlist'
import { useAddItemToListModal } from '../../modals/add-item-to-list'
import { login, useLogin } from '../../lib/login'
import { ReleaseArtistLink } from '../release-artist-link'
import { useRemoveReleaseFromCollectionMutation } from '../../mutations/remove-release-from-collection'
import { LabelVersions } from '../label-releases/labelVersionsUi'
import { CatalogNumbers } from '../../lib/components/catalogNumbers'
import { NSFWImagePlaceholderSmall } from '../../lib/components/nsfw-image-placeholder'
import { THUMBNAIL } from '../../lib/components/nsfw-image-placeholder/constants'
import { explicitMasterContent, explicitReleaseContent } from '../../lib/explicit-contentIds'

export type RenderProps = {
    masterData: ArtistReleaseGroupDescription['keyRelease']
    view: string
    versionCount?: number
    formats: string[] | []
    labels?: string[] | []
    countries: string[] | []
    years: number[] | []
    genres?: string[] | []
    roles?: string[] | []
    creditCategory: string
    releaseTypes?: string[]
    artistId: number
    orderByValue?: string | undefined
    artistName: string
    anvs?: string[]
    search: string
    groupingId?: string
    itemType?: ItemType
    labelId?: number
    labelCatalogNumber?: string
}

export function ArtistVersionTableRender(props: RenderProps): ReactElement | null {
    const {
        masterData,
        view,
        versionCount,
        formats,
        labels,
        countries,
        genres,
        years,
        roles,
        artistId,
        creditCategory,
        releaseTypes,
        orderByValue,
        artistName,
        anvs,
        search,
        groupingId,
        itemType,
        labelId,
        labelCatalogNumber,
    } = props

    const [open, setOpen] = useState(false)

    const yearsSelected = years.map(Number)

    const [loadReleases, { loading, data }] = useLabelReleaseGroupReleasesLazyQuery({
        variables: {
            // @ts-expect-error - TODO return to type this correctly
            discogsId: labelId,
            groupingId: groupingId ?? '',
            search,
            countries,
            formats,
            genres,
            creditCategories: roles ?? [],
            years: yearsSelected,
        },
        skip: !labelId,
    })

    function toggleOpen(): void {
        setOpen((open) => !open)
        if (!data && labelId) {
            loadReleases().catch((e) => {
                throw new Error('artist-views loadReleases error')
            })
        }
    }

    return (
        <>
            <Master
                orderByValue={orderByValue}
                versionCount={versionCount}
                keyRelease={masterData}
                view={view}
                open={open}
                onToggleOpen={toggleOpen}
                artistName={artistName}
                creditCategory={creditCategory}
                labelCatalogNumber={labelCatalogNumber}
                itemType={itemType}
            />
            {open && itemType === 'LABEL' ? (
                <LabelVersions data={data} labelLoading={loading} view={view} labelCatalogNumber={labelCatalogNumber} />
            ) : (
                open && (
                    <ArtistVersions
                        keyReleaseDiscogsId={masterData.discogsId}
                        creditCategory={creditCategory}
                        releaseTypes={releaseTypes}
                        discogsId={artistId}
                        formats={formats}
                        labels={labels}
                        view={view}
                        countries={countries}
                        years={years}
                        onToggleOpen={toggleOpen}
                        anvs={anvs}
                        search={search}
                    />
                )
            )}
        </>
    )
}

type VersionsProps = {
    keyReleaseDiscogsId: number
    creditCategory: string
    releaseTypes?: string[]
    discogsId: number
    formats?: string[] | []
    labels?: string[] | []
    countries?: string[] | []
    years?: number[] | []
    view: string
    onToggleOpen: () => void
    anvs?: string[]
    search?: string
}

function ArtistVersions(props: VersionsProps): ReactElement | null {
    const {
        discogsId,
        creditCategory,
        releaseTypes,
        keyReleaseDiscogsId,
        onToggleOpen,
        formats,
        labels,
        countries,
        years,
        view,
        anvs,
        search = '',
    } = props

    const yearsSelected = years?.map(Number)

    const res = useArtistReleasesDataQuery({
        ssr: false,
        returnPartialData: true,
        errorPolicy: 'all',
        variables: {
            discogsId,
            creditCategory,
            releaseTypes,
            keyReleaseDiscogsId,
            formats,
            labels,
            countries,
            anvs,
            search,
            years: yearsSelected,
        },
        skip: !discogsId,
    })

    if (!res.data?.artist?.releases || res.loading) {
        return (
            <tr>
                <td className={css.loadingSpinner}>
                    <Loading height={100} />
                </td>
            </tr>
        )
    }

    const { data: versionsData } = res

    // the following code is a work around until a broader conversation can
    // be had re pulling/returning the correct data from the api

    const creditVersions = []
    const uniqueDiscogsIds = new Set()

    for (const individualRelease of versionsData.artist?.releases.releaseGroupDetails ?? []) {
        const discogsId = individualRelease?.release.discogsId
        if (discogsId && !uniqueDiscogsIds.has(discogsId)) {
            uniqueDiscogsIds.add(discogsId)
            creditVersions.push(individualRelease.release)
        }
    }

    const versions =
        creditCategory === 'Credits'
            ? creditVersions
            : versionsData.artist?.releases.releaseGroupDetails.map((individualRelease) => individualRelease?.release)

    return (
        <>
            {versions &&
                versions.map((version) => (
                    <Version
                        // @ts-expect-error TODO type version correctly
                        version={version}
                        key={version?.discogsId}
                        view={view}
                    />
                ))}
            <td className={css.closeVersionsButton}>
                <button onClick={onToggleOpen} className={css.closeVersionsButton}>
                    <Trans>Close Versions</Trans> <AngleUp aria-hidden='true' />
                </button>
            </td>
        </>
    )
}

type MasterProps = {
    formats?: Format[]
    labels?: LabelRelationship[]
    versionCount: number | undefined
    view: string
    masterRelease?: { discogsId: number; siteUrl: string }
    keyRelease: ArtistReleaseGroupDescription['keyRelease']
    loading?: boolean
    collectionItems?: CollectionItemConnection | null | undefined
    primaryArtists?: PrimaryArtist[]
    orderByValue?: string | undefined
    open: boolean
    onToggleOpen: () => void
    artistName: string
    creditCategory: string
    labelCatalogNumber?: string
    itemType?: ItemType
}

function Master(props: MasterProps): ReactElement | null {
    const {
        versionCount,
        keyRelease,
        view,
        loading,
        open,
        onToggleOpen,
        orderByValue,
        artistName,
        creditCategory,
        labelCatalogNumber,
        itemType,
    } = props

    const { loggedIn } = useLogin()
    const { i18n } = useLocalisation()
    // occasionally ES serves up a null release.
    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
    if (!keyRelease) {
        return null
    }

    const {
        masterRelease,
        siteUrl,
        formats,
        labels,
        collectionItems,
        primaryArtists,
        discogsId,
        title,
        inWantlist,
        tracks,
        myListings,
    } = keyRelease

    function displayCreditedTrack(data: Release['tracks'], artistName: string, creditCategory: string): string | null {
        if (creditCategory === 'Releases' || !data) {
            return null
        }

        // tracks that the current artist are credited on are displayed in the discography
        // when a user is looking at any superFacet other then Releases.
        // This data lives in three places. SubTracks, trackCredits or primaryArtists.

        if (data[0].subTracks) {
            for (const track of data[0].subTracks) {
                if (track.primaryArtists) {
                    for (const artist of track.primaryArtists) {
                        if (artist.displayName === artistName) {
                            return track.title
                        }
                    }
                }
            }
        }

        const foundTrackViaCredits = data.find((track) =>
            track.trackCredits?.some((credit) => credit.displayName === artistName),
        )

        const foundTrackViaDisplayName = data.find((track) =>
            track.primaryArtists?.some((credit) => credit.displayName === artistName),
        )

        if (foundTrackViaCredits) {
            return foundTrackViaCredits.title
        }

        if (foundTrackViaDisplayName) {
            return foundTrackViaDisplayName.title
        }

        return null
    }

    const isNotMaster = versionCount && versionCount < 2

    const thumb =
        itemType === 'LABEL' && !isNotMaster && masterRelease?.keyRelease.images.edges[0]?.node
            ? masterRelease.keyRelease
            : keyRelease

    const releaseLink = masterRelease && versionCount && versionCount > 1 ? masterRelease.siteUrl : siteUrl

    const artistReleased = orderByValue ? (Number(orderByValue) > 0 ? year(orderByValue) : null) : null

    const labelReleased = keyRelease.released

    const actionsId = masterRelease && versionCount && versionCount > 1 ? masterRelease.discogsId : discogsId

    const catalogNumbers = labelCatalogNumber ? labelCatalogNumber : labels

    const labelDescriptors =
        isNotMaster || itemType !== 'LABEL' ? [] : [...(masterRelease?.keyRelease.formats ?? []), ...(formats ?? [])]

    function masterThumbnail(discogsId: number, isNotMaster: boolean | undefined | 0): ReactElement {
        const hasNSFWImage = thumb.images.edges[0]?.node?.nsfw
        const label = t(i18n)`Navigate to ${title}`

        const blockForLoggedInUsers =
            explicitReleaseContent.includes(discogsId) || explicitMasterContent.includes(discogsId)

        const isRelease = isNotMaster ? discogsId : 0
        const releaseTypeCheck = explicitMasterContent.includes(isRelease)

        if ((hasNSFWImage && !loggedIn) || (blockForLoggedInUsers && !releaseTypeCheck)) {
            return (
                <Link href={releaseLink} aria-label={label} tabIndex={-1}>
                    <div className={css.nsfwImageContainer}>
                        <NSFWImagePlaceholderSmall variant={THUMBNAIL} showButton={false} />
                    </div>
                </Link>
            )
        }
        return <Thumbnail keyRelease={thumb} title={title} imageLink={releaseLink} />
    }

    return (
        <tr className={view === 'textWithCovers' ? css.textWithCoversRow : css.textOnlyRow}>
            {view === 'textWithCovers' && <td>{masterThumbnail(discogsId, isNotMaster)}</td>}
            <td className={css.mobileStacked}>
                <div>
                    {displayCreditedTrack(tracks, artistName, creditCategory) && (
                        <>
                            {displayCreditedTrack(tracks, artistName, creditCategory)}
                            <br />
                        </>
                    )}
                    {(primaryArtists && primaryArtists.length > 1) ||
                    (primaryArtists && primaryArtists[0].displayName !== artistName) ? (
                        <span>
                            <ReleaseArtistLink artists={primaryArtists} />
                            <span> &ndash; </span>
                            <Link href={releaseLink}>
                                <Trans>{title}</Trans>
                            </Link>
                            {itemType === 'LABEL' && (
                                <span>
                                    <Formats formats={labelDescriptors} itemType={itemType} />
                                </span>
                            )}
                        </span>
                    ) : (
                        <span>
                            {displayCreditedTrack(tracks, artistName, creditCategory) && (
                                <>
                                    {displayCreditedTrack(tracks, artistName, creditCategory)}
                                    <br />
                                </>
                            )}
                            <Link href={releaseLink}>
                                <Trans>{title}</Trans>
                            </Link>
                        </span>
                    )}
                    {isNotMaster && (
                        <span>
                            {' '}
                            <Formats formats={formats} />
                        </span>
                    )}
                </div>
                <div>
                    <Labels labels={labels} />
                </div>
                <span>
                    <CatalogNumbers catalogNumbers={catalogNumbers} hasVersions={isNotMaster} />
                </span>
                <div>
                    {artistReleased ? (
                        artistReleased
                    ) : labelReleased ? (
                        year(labelReleased)
                    ) : (
                        <span className={css.unknown}>
                            <Trans>Unknown</Trans>
                        </span>
                    )}
                </div>
            </td>
            <td className={css.title}>
                {displayCreditedTrack(tracks, artistName, creditCategory)}
                {(primaryArtists && primaryArtists.length > 1) ||
                (primaryArtists && primaryArtists[0].displayName !== artistName) ? (
                    <span>
                        <ReleaseArtistLink artists={primaryArtists} />
                        <span> &ndash; </span>
                        <Link href={releaseLink}>
                            <Trans>{title}</Trans>
                        </Link>
                        {itemType === 'LABEL' && (
                            <span>
                                <Formats formats={labelDescriptors} itemType={itemType} />
                            </span>
                        )}
                    </span>
                ) : (
                    <Link href={releaseLink}>
                        <Trans>{title}</Trans>
                    </Link>
                )}
                {isNotMaster && <Formats formats={formats} />}
                {masterRelease && versionCount && versionCount > 1 && (
                    <span>
                        <button onClick={onToggleOpen} className={css.versionsButton} aria-expanded={open}>
                            <span>
                                {['Releases', 'Appearances', 'Unofficial'].includes(creditCategory)
                                    ? t(i18n)`${plural(versionCount, { one: '# version', other: '# versions' })}`
                                    : t(i18n)`${plural(versionCount, { one: '# credit', other: '# credits' })}`}
                                {loading ? (
                                    <Loading height='1rem' />
                                ) : open ? (
                                    <AngleUp aria-hidden='true' />
                                ) : (
                                    <AngleDown aria-hidden='true' />
                                )}
                            </span>
                        </button>
                    </span>
                )}
            </td>
            <td className={css.discographyLabel}>
                <Labels labels={labels} />
            </td>
            <td className={css.cat}>
                <span>
                    <CatalogNumbers catalogNumbers={catalogNumbers} hasVersions={isNotMaster} />
                </span>
            </td>
            <td className={css.emptyCell} />
            <td className={css.year}>
                {artistReleased ? (
                    artistReleased
                ) : labelReleased ? (
                    year(labelReleased)
                ) : (
                    <span className={css.unknown}>
                        <Trans>Unknown</Trans>
                    </span>
                )}
            </td>
            <td className={css.skittles}>
                <Skittle
                    type='collection'
                    count={isNotMaster ? collectionItems?.totalCount ?? 0 : masterRelease?.inUserCollectionCount ?? 0}
                />
                <Skittle
                    type='wantlist'
                    count={isNotMaster ? (inWantlist ? 1 : 0) : masterRelease?.inUserWantlistCount ?? 0}
                />
                <Skittle
                    type='inventory'
                    count={isNotMaster ? myListings?.totalCount ?? 0 : masterRelease?.myListings?.totalCount ?? 0}
                />
            </td>
            <td className={css.actionstd}>
                <Actions
                    discogsId={actionsId}
                    versionCount={versionCount}
                    collectionItems={collectionItems}
                    wantlist={inWantlist?.discogsId}
                    masterRelease={masterRelease}
                    inWantlist={inWantlist}
                />
            </td>
            {masterRelease && versionCount && versionCount > 1 && (
                <td className={open ? css.mobileVersionsButtonOpen : css.mobileVersionsButtonClosed}>
                    <button onClick={onToggleOpen}>
                        {t(i18n)`${plural(versionCount, { one: '# version', other: '# versions' })}`}
                        {loading ? (
                            <Loading height='1rem' />
                        ) : open ? (
                            <AngleUp aria-hidden='true' />
                        ) : (
                            <AngleDown aria-hidden='true' />
                        )}
                    </button>
                </td>
            )}
        </tr>
    )
}

type ThumbnailProps = {
    keyRelease: NonNullable<NonNullable<VersionEdge>['node']>
    title: string
    imageLink: string
}

function Thumbnail(props: ThumbnailProps): ReactElement {
    const { keyRelease, title, imageLink } = props
    const image = keyRelease.images.edges[0]?.node
    const [error, setError] = useState(false)
    const { i18n } = useLocalisation()
    const alt = t(i18n)`Cover of ${title}`
    const label = t(i18n)`Navigate to ${title}`

    if (!image || error) {
        return (
            <Link href={imageLink} role='button' aria-label={label} tabIndex={-1}>
                <div className={css.fallback} role='button' aria-disabled='true' aria-label={label}>
                    <Icon
                        format={asFormat(keyRelease.formats?.[0].name)}
                        width={50}
                        alt={i18n._(t`No image available`)}
                    >
                        <title>
                            <Trans>No image available; add an image</Trans>
                        </title>
                    </Icon>
                </div>
            </Link>
        )
    }

    function handleError(): void {
        setError(true)
    }

    const dims = dimensions(image.tiny, 80, 80)

    return (
        <Link href={imageLink} role='button' aria-label={label} tabIndex={-1}>
            <img
                src={image.tiny.sourceUrl}
                alt={alt}
                loading='lazy'
                onError={handleError}
                width={dims.width}
                height={dims.height}
            />
            <ZoomIn className={css.zoom} aria-hidden='true' style={{ top: dims.height / 2 }} />
        </Link>
    )
}

type ReleaseProps = {
    version: Partial<TSRelease> | undefined
    view: string
    collectionItems: CollectionItemConnection | undefined
    labelCatalogNumber?: string
}

export function Version(props: ReleaseProps): ReactElement | null {
    const { version, view, labelCatalogNumber } = props

    if (!version || !version.discogsId) {
        return null
    }

    const {
        title,
        formats,
        labelsNew,
        country,
        released,
        siteUrl,
        collectionItems,
        inWantlist,
        myListings,
        discogsId,
        masterRelease,
    } = version

    const catalogNumbers = labelCatalogNumber ? labelCatalogNumber : labelsNew

    return (
        <tr
            className={view === 'textWithCovers' ? css.versionsTextWithCoversRow : css.versionsTextOnlyRow}
            id={css.versionsTr}
        >
            <td className={css.mobileVersionsContainer}>
                <table>
                    <tr className={css.mobileStackedVersions}>
                        <td id={css.mobileVersionTd}>
                            <Link href={siteUrl}>{title}</Link> <Formats formats={formats} />
                        </td>
                        <td id={css.mobileVersionTd}>
                            {released ? (
                                year(released)
                            ) : (
                                <td>
                                    <Trans>Unknown</Trans>
                                </td>
                            )}
                        </td>
                        <td id={css.mobileVersionTd}>{country}</td>
                        <td id={css.mobileVersionTd}>
                            <Labels labels={labelsNew} />
                        </td>
                        <td id={css.mobileVersionTd}>
                            <CatalogNumbers catalogNumbers={catalogNumbers} hasVersions />
                        </td>
                    </tr>
                </table>
            </td>

            {view === 'textWithCovers' && <td className={css.emptyCell} />}
            <td className={css.title}>
                <Link href={siteUrl}>{title}</Link> <Formats formats={formats} />
            </td>
            <td className={css.discographyLabel}>
                <Labels labels={labelsNew} />
            </td>
            <td className={css.cat}>
                <CatalogNumbers catalogNumbers={catalogNumbers} hasVersions />
            </td>
            <td className={css.country}>{country}</td>
            <td className={css.year}>
                {released ? (
                    year(released)
                ) : (
                    <td className={css.unknown}>
                        <Trans>Unknown</Trans>
                    </td>
                )}
            </td>
            <td className={css.skittles}>
                <Skittle type='collection' count={collectionItems?.totalCount ?? 0} />
                <Skittle type='wantlist' count={inWantlist?.discogsId ? 1 : 0} />
                <Skittle type='inventory' count={myListings?.totalCount ?? 0} />
            </td>
            <td className={css.actionstd}>
                <Actions
                    discogsId={discogsId}
                    wantlist={inWantlist?.discogsId}
                    collectionItems={collectionItems}
                    masterRelease={masterRelease}
                    inWantlist={inWantlist}
                />
            </td>
        </tr>
    )
}

type FormatProps = {
    formats?: Format[]
    itemType?: ItemType
}

function Formats(props: FormatProps): ReactElement | null {
    const { formats, itemType } = props

    if (!formats) {
        return null
    }

    const abbreviations = {
        'Special Edition': 'S/Edition',
        Compilation: 'Comp',
        'Single Sided': 'S/Sided',
        'Limited Edition': 'Ltd',
        Reissue: 'RE',
        Numbered: 'Num',
        'Box Set': 'Box',
        'Picture Disc': 'Pic',
        'Jewel Case': 'Jew',
        'HX Pro B NR': 'HX',
        'White Label': 'W/Lbl',
        'No Publisher Text On Cds': 'No',
        Remastered: 'RM',
        Clear: 'Cle',
        'Card Sleeve': 'Car',
        Digipak: 'Dig',
        Enhanced: 'Enh',
        'SHM-CD': 'SHM',
        'Cardboard Sleeve': 'CAR',
        '45 RPM': null,
    }

    function filterDescriptions(data: FormatProps['formats']): string[] {
        if (!data) {
            return []
        }
        return data.flatMap((item) =>
            item.description
                ? item.description.filter((desc) =>
                      ['Album', 'Compilation', 'Single', 'Maxi', 'Mini-Album'].includes(desc),
                  )
                : [],
        )
    }

    if (itemType === 'LABEL') {
        const formatDescriptors = filterDescriptions(formats)

        return formatDescriptors.length > 0 ? (
            <div className={css.formatsContainer}>
                (
                <Fragment key={formatDescriptors[0]}>
                    {formatDescriptors[0] === 'Compilation' ? 'Comp' : formatDescriptors[0]}
                </Fragment>
                )
            </div>
        ) : null
    }

    return (
        <div className={css.formatsContainer}>
            (
            {intersperse(
                ', ',
                formats.map(function (format, index): ReactElement {
                    const { name, quantity, description, text } = format
                    return (
                        /* eslint-disable react/no-array-index-key */
                        <Fragment key={`${name}.${index}`}>
                            {quantity && quantity !== '1' && <>{quantity}&times;</>}
                            {name !== 'Vinyl' && name}
                            {description && description.length > 0 && name !== 'Vinyl' ? ', ' : ''}
                            {description &&
                                intersperse(
                                    ', ',
                                    description
                                        .filter((desc) => abbreviations[desc as keyof typeof abbreviations] !== null)
                                        .map((desc) => abbreviations[desc as keyof typeof abbreviations] ?? desc),
                                )}
                            {text && (
                                <i>
                                    {`, `}
                                    {text}
                                </i>
                            )}
                        </Fragment>
                    )
                }),
            )}
            )
        </div>
    )
}

type LabelProps = {
    labels?: LabelRelationship[]
}

function Labels(props: LabelProps): ReactElement | null {
    const { labels } = props

    if (!labels) {
        return null
    }

    const filtered = labels.filter(
        (label): boolean => label.labelRole === ReleaseLabelRole.Label && Boolean(label.catalogNumber),
    )

    return <ReleaseLabelLink labels={filtered} noCatNos />
}

type ActionsProps = {
    discogsId: number
    wantlist?: number
    versionCount?: number
    collectionItems: CollectionItemConnection | null | undefined
    masterRelease: MasterRelease | undefined
    inWantlist: WantlistItem | undefined
}

function Actions(props: ActionsProps): ReactElement {
    const { discogsId, wantlist, versionCount, collectionItems, masterRelease } = props
    const [toggle, setToggle] = useState({ master: false, release: false })

    const { loggedIn } = useLogin()
    const addToCollectionMutation = useAddReleaseToCollectionMutation()
    const addToWantlistMutation = useAddReleasesToWantlistMutation()
    const removeFromWantlistMutation = useRemoveReleasesFromWantlistMutation()
    const remove = useRemoveReleaseFromCollectionMutation()
    const addItemToList = useAddItemToListModal()
    const track = useInteraction()

    const masterRef = useRef(null)
    const releaseRef = useRef(null)

    function closePopup() {
        setToggle({ master: false, release: false })
    }

    useClickOutside(masterRef, closePopup)
    useClickOutside(releaseRef, closePopup)

    useEffect(function () {
        if (document.activeElement !== masterRef.current) {
            setToggle({ ...toggle, master: false })
        }
        if (document.activeElement !== releaseRef.current) {
            setToggle({ ...toggle, release: false })
        }
    }, [])

    function addToCollection(discogsId: number) {
        if (!loggedIn) {
            login()
            return
        }
        void addToCollectionMutation.perform({ discogsId })
    }

    const collectionItem = collectionItems?.edges.map((edge: CollectionItemEdge) => edge.node)

    function removeFromCollection(discogsId: number, collectionItem: CollectionItem[] | undefined): void {
        if (!loggedIn) {
            login()
            return
        }
        if (!collectionItem) {
            return
        }
        collectionItem.forEach(function (item) {
            const { discogsId: itemId } = item
            void remove.perform({ discogsId, itemId })
        })
    }

    function addToWantlist(discogsIds: number[]) {
        if (!loggedIn) {
            login()
            return
        }
        void addToWantlistMutation.perform({ discogsIds })
    }

    function onAddToList(discogsId: number, itemType: string) {
        if (!loggedIn) {
            login()
            return
        }
        track('Filter - Add to List')
        if (itemType === 'master') {
            addItemToList.open(ItemType.MasterRelease, discogsId)
        } else {
            addItemToList.open(ItemType.Release, discogsId)
        }
    }

    function removeFromWantlist(discogsIds: number[]) {
        if (!loggedIn) {
            login()
            return
        }
        void removeFromWantlistMutation.perform({ discogsIds })
    }

    return masterRelease && versionCount && versionCount > 1 ? (
        <>
            <button
                ref={masterRef}
                onClick={() => setToggle({ release: false, master: !toggle.master })}
                aria-label='actions dropdown'
                className={css.actionsDropdown}
                aria-expanded={toggle.master}
                aria-haspopup='menu'
            >
                <span>
                    <EllipsisH aria-hidden='true' />
                </span>
                {/* eslint-disable jsx-a11y/role-supports-aria-props */}
                <ul aria-modal={toggle.master} role='menu'>
                    <li>
                        <button onClick={() => onAddToList(discogsId, 'master')}>Add to list</button>
                    </li>
                    <li>
                        <Link role='button' href={`/master/${discogsId}/history#latest`}>
                            Edit Master Release
                        </Link>
                    </li>
                </ul>
            </button>
            {addItemToList.component}
        </>
    ) : (
        <>
            <button
                ref={releaseRef}
                onClick={() => setToggle({ release: !toggle.release, master: false })}
                aria-label='actions dropdown'
                className={css.actionsDropdown}
                aria-expanded={toggle.release}
                aria-haspopup='menu'
            >
                <span>
                    <EllipsisH aria-hidden='true' />
                </span>
                {/* eslint-disable jsx-a11y/role-supports-aria-props */}
                <ul aria-modal={toggle.release} role='menu'>
                    <li>
                        <button onClick={() => addToCollection(discogsId)}>Add to Collection</button>
                    </li>
                    {collectionItems?.totalCount && collectionItems.totalCount < 2 ? (
                        <li>
                            <button onClick={() => removeFromCollection(discogsId, collectionItem as CollectionItem[])}>
                                Remove from Collection
                            </button>
                        </li>
                    ) : null}
                    {wantlist ? (
                        <li>
                            <button onClick={() => removeFromWantlist([discogsId])}>Remove from Wantlist</button>
                        </li>
                    ) : (
                        <li>
                            <button onClick={() => addToWantlist([discogsId])}>Add to Wantlist</button>
                        </li>
                    )}
                    <li>
                        <button onClick={() => onAddToList(discogsId, 'release')}>Add to List</button>
                    </li>
                    <li>
                        <Link href={`/sell/post/${discogsId}`} role='button'>
                            Sell This Item
                        </Link>
                    </li>
                    <li>
                        <Link href={`/release/${discogsId}/history#latest`} role='button'>
                            Edit Release
                        </Link>
                    </li>
                </ul>
            </button>
            {addItemToList.component}
        </>
    )
}
