import { ReactElement } from 'react'
import { AngleDown, AngleUp } from '../icon'
import css from './styles.css'

type Props = {
    isShowingMore: boolean
    onToggle: (more: boolean) => void
    setIsHeaderExpanded?: (key: boolean) => void
    scroll?: boolean
    position?: number
}

export function ShowMore(props: Props): ReactElement | null {
    const { isShowingMore, onToggle, setIsHeaderExpanded, scroll, position = 0 } = props

    function clickHandler(showingState: boolean) {
        if (setIsHeaderExpanded) {
            if (showingState) {
                setIsHeaderExpanded(false)
                onToggle(false)
                if (scroll) {
                    window.scrollTo({
                        top: position,
                        behavior: 'smooth',
                    })
                }
                return
            }
            setIsHeaderExpanded(true)
            onToggle(true)
            return
        }

        onToggle(!isShowingMore)
    }

    if (!isShowingMore) {
        return (
            <button className={css.showMoreInfoContainer} onClick={() => clickHandler(isShowingMore)}>
                Show more
                <AngleDown className={css.moreInfo} aria-hidden='true' />
            </button>
        )
    }
    return (
        <button className={css.showMoreInfoContainer} onClick={() => clickHandler(isShowingMore)}>
            Show less
            <AngleUp className={css.moreInfo} aria-hidden='true' />
        </button>
    )
}
