import { ReactElement } from 'react'
import { Link } from '../link'
import { useDidYouMeanQuery } from '../../../api/types'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Trans } from '@lingui/macro'

type RouteParams = {
    discogsId: string
    slug?: string
}

type DidYouMeanObj = {
    bannerText: ReactElement | null
    display: { siteUrl: string; page: string } | null
}

export function DidYouMeanBannerContent(): DidYouMeanObj {
    const { discogsId } = useParams<RouteParams>()
    const history = useHistory()
    const location = useLocation()

    // have we been redirected from a legacy url
    const searchParams = new URLSearchParams(location.search)
    const redirected = searchParams.get('redirected')

    const query = useDidYouMeanQuery({
        ssr: true,
        returnPartialData: true,
        errorPolicy: 'all',
        variables: { name: discogsId },
        skip: !redirected,
    })

    if (!redirected) {
        return {
            bannerText: null,
            display: null,
        }
    }

    const labelPage = history.location.pathname.includes('label')

    const artistPage = history.location.pathname.includes('artist')

    const { data } = query

    const display =
        data?.label?.siteUrl && labelPage
            ? { siteUrl: data.label.siteUrl, page: 'label' }
            : data?.artist?.siteUrl && artistPage
            ? { siteUrl: data.artist.siteUrl, page: 'artist' }
            : null

    return {
        bannerText: (
            <b>
                <Trans>
                    Did you mean the {display?.page} named {display && <Link href={display.siteUrl}>{discogsId}</Link>}
                </Trans>
            </b>
        ),
        display,
    }
}
