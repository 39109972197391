import { ReactElement, forwardRef, useRef, useState } from 'react'
import { CaretDown, Check } from '../../../lib/components/icon'
import { esc } from '../../keycodes'
import css from './desktop.css'
import { Trans, t } from '@lingui/macro'
import { set, useQueryParams } from '../../../lib/query'
import { Chip } from '../../../lib/components/chip'
import classnames from 'classnames'
import { Progressbar } from '../../../lib/components/progressbar'
import { useLocalisation } from '../../../lib/i18n'

import { Dispatcher } from '../../add-state'
import { SearchBar } from '../basicSearch'
import { useHistory, useLocation } from 'react-router'
import { useClickOutside } from '../../use-click-outside'
import { useKeyPress } from '../../use-key-press'

export type FacetObjects = {
    [key: string]: { subFacet: { totalCount: number; subFacetName: string }[]; totalCount: number }
}

type FacetFiltersProps = {
    data:
        | {
              year: FacetObjects['year']
              format: FacetObjects['format']
              years: FacetObjects['years']
              genres: FacetObjects['genres']
              label: FacetObjects['label']
              role: FacetObjects['role']
          }
        | {}
    dispatch: Dispatcher<Actions>
    labelSelected: string[]
    formatSelected: string[]
    countrySelected: string[]
    yearSelected?: number[]
    genreSelected?: string[]
    roleSelected?: string[]
    advancedFilterSelected: { [key: string]: boolean }
    loading: boolean
    search: string
    inputValues: string[]
    advancedFilterShowState: {
        desktop: boolean | null
        mobile: boolean
    }
}

type AdvancedFiltersAppliedAction = {
    type: 'advancedFiltersApplied'
    formats?: string[]
    labels?: string[]
    countries?: string[]
    years?: number[]
    genres?: string[]
    roles?: string[]
}

type SearchBarAction = {
    type: 'search'
    search: string
}

type AdvancedFilterSelectedAction = {
    type: 'advancedFilterSelected'
    advancedFilterSelected?: {
        [key: string]: boolean
    }
    advancedFilterShowState?: {
        desktop: boolean | null
        mobile: boolean
    }
}

type SideBarFilters = {
    type: 'sidebarFilters'
    filterSelected: string[]
    initialFilter: string[]
}

export type Actions = AdvancedFiltersAppliedAction | AdvancedFilterSelectedAction | SearchBarAction | SideBarFilters

type IsOpen = { format: false; label: false; year: false; country: false; genre: false; role: false }

type FilterProps = {
    inputValue: string
    dispatch: Dispatcher<Actions>
    isOpen: IsOpen
    isFocused: boolean
    data: { format: FacetObjects['format']; label: FacetObjects['label'] } | {}
    label: string
    setIsOpen: (key: IsOpen) => void
    id: number
    labelSelected: string[] | []
    formatSelected: string[] | []
    countrySelected: string[] | []
    yearSelected?: number[] | []
    genreSelected?: string[] | []
    roleSelected?: string[] | []
    advancedFilterSelected: { [key: string]: boolean }
    advancedFilterShowState: {
        desktop: boolean | null
        mobile: boolean
    }
}

const FilterSelectUI = forwardRef<HTMLDivElement, FilterProps>(function FilterSelectUI(props, ref): ReactElement {
    const {
        id = 'filter-select',
        inputValue,
        dispatch,
        isOpen,
        isFocused,
        data,
        label,
        setIsOpen,
        labelSelected,
        formatSelected,
        countrySelected,
        genreSelected,
        yearSelected,
        roleSelected,
        advancedFilterSelected,
        advancedFilterShowState,
    } = props

    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    if (!data) {
        return <div />
    }

    function handleButtonClick(label: string) {
        setIsOpen({
            ...isOpen,
            [label]: !isOpen[label as keyof typeof isOpen],
        })
    }
    const labels =
        label.charAt(0).toUpperCase() + label.slice(1) === 'Label'
            ? 'Labels & Companies'
            : label.charAt(0).toUpperCase() + label.slice(1)

    return (
        <div className={css.wrapper}>
            <label className={css.label}>{labels}</label>
            <div
                className={classnames(css.box, isFocused && css.focused)}
                role='combobox'
                aria-label={label}
                aria-haspopup='listbox'
                aria-controls={`${id}-listbox`}
                aria-expanded={isOpen.label}
                ref={ref}
            >
                <input
                    className={css.input}
                    readOnly
                    onClick={() => handleButtonClick(label)}
                    onKeyDown={function (e) {
                        if (e.key === 'Enter') {
                            handleButtonClick(label)
                        }
                    }}
                    defaultValue={inputValue}
                    aria-autocomplete='list'
                    aria-controls={`${id}-listbox`}
                />
                <button
                    className={css.button}
                    type='button'
                    onClick={() => handleButtonClick(label)}
                    tabIndex={-1}
                    aria-haspopup='listbox'
                    aria-expanded={isOpen.label}
                    aria-label='Show options'
                    disabled={!Object.keys(data).length}
                >
                    <CaretDown aria-hidden />
                </button>
            </div>
            <ListBox
                isOpen={isOpen}
                data={data}
                dispatch={dispatch}
                id={id}
                label={label}
                labelSelected={labelSelected}
                countrySelected={countrySelected}
                formatSelected={formatSelected}
                genreSelected={genreSelected}
                yearSelected={yearSelected}
                roleSelected={roleSelected}
                advancedFilterSelected={advancedFilterSelected}
                advancedFilterShowState={advancedFilterShowState}
            />
        </div>
    )
})

export function FacetFilter(props: FacetFiltersProps): ReactElement {
    const {
        data,
        dispatch,
        labelSelected = [],
        yearSelected = [],
        genreSelected = [],
        formatSelected = [],
        countrySelected = [],
        roleSelected = [],
        advancedFilterSelected,
        loading,
        search,
        advancedFilterShowState,
        inputValues,
    } = props

    const history = useHistory()
    const location = useLocation()
    const [superFilter] = useQueryParams('superFilter')
    const [creditFilter] = useQueryParams('creditFilter')
    const [subFilter] = useQueryParams('subFilter')

    const [isOpen, setIsOpen] = useState<IsOpen>({
        format: false,
        label: false,
        year: false,
        country: false,
        genre: false,
        role: false,
    })

    const ref = useRef(null)

    const { i18n } = useLocalisation()

    function closePopup() {
        setIsOpen({ format: false, label: false, year: false, country: false, genre: false, role: false })
    }

    useClickOutside(ref, closePopup)

    const chips = [
        ...formatSelected,
        ...labelSelected,
        ...countrySelected,
        ...yearSelected,
        ...genreSelected,
        ...roleSelected,
    ].filter(function (item) {
        for (const key in data) {
            if (!data[key as keyof typeof data]) {
                ;(data as Record<string, unknown[]>)[key] = []
            }
            if (item in data[key as keyof typeof data]) {
                return true
            }
        }
        return false
    })

    function clearAll(): void {
        for (const key in advancedFilterSelected) {
            advancedFilterSelected[key] = false
        }
        dispatch({
            type: 'advancedFiltersApplied',
            formats: [],
            labels: [],
            countries: [],
            genres: [],
            years: [],
        })
        dispatch({
            type: 'search',
            search: '',
        })

        history.push(
            set(location, {
                searchParam: null,
                format: null,
                role: null,
                country: null,
                genre: null,
                year: null,
                label: null,
                page: null,
            }),
        )
    }

    useKeyPress({
        [esc](): void {
            if (Object.keys(advancedFilterSelected).length !== 0) {
                clearAll()
            }
        },
    })

    function chipHandler(selection: string | number): string[] | number[] | null {
        const updatedFilterSelections = { ...advancedFilterSelected }

        updatedFilterSelections[selection] = false

        dispatch({
            type: 'advancedFilterSelected',
            advancedFilterSelected: updatedFilterSelections,
            advancedFilterShowState,
        })

        if (labelSelected.includes(selection as string)) {
            const remainingFilters = labelSelected.filter((val) => val !== selection)
            dispatch({
                type: 'advancedFiltersApplied',
                labels: remainingFilters,
                formats: formatSelected,
                countries: countrySelected,
                years: yearSelected,
                genres: genreSelected,
                roles: roleSelected,
            })
            history.push(
                set(location, {
                    label: labelSelected.filter((val) => val !== selection),
                    page: null,
                }),
            )
            return remainingFilters
        }

        if (formatSelected.includes(selection as string)) {
            const remainingFilters = formatSelected.filter((val) => val !== selection)
            dispatch({
                type: 'advancedFiltersApplied',
                formats: remainingFilters,
                labels: labelSelected,
                countries: countrySelected,
                years: yearSelected,
                genres: genreSelected,
                roles: roleSelected,
            })
            history.push(
                set(location, {
                    format: formatSelected.filter((val) => val !== selection),
                    page: null,
                }),
            )
            return remainingFilters
        }

        if (countrySelected.includes(selection as string)) {
            const remainingFilters = countrySelected.filter((val) => val !== selection)
            dispatch({
                type: 'advancedFiltersApplied',
                countries: remainingFilters,
                formats: formatSelected,
                labels: labelSelected,
                years: yearSelected,
                genres: genreSelected,
                roles: roleSelected,
            })
            history.push(
                set(location, {
                    country: countrySelected.filter((val) => val !== selection),
                    page: null,
                }),
            )
            return remainingFilters
        }
        if (yearSelected.includes(selection as number)) {
            const remainingFilters = yearSelected.filter((val) => val !== selection)
            dispatch({
                type: 'advancedFiltersApplied',
                labels: labelSelected,
                formats: formatSelected,
                countries: countrySelected,
                years: remainingFilters,
                genres: genreSelected,
                roles: roleSelected,
            })
            history.push(
                set(location, {
                    year: yearSelected.filter((val) => val !== selection),
                    page: null,
                }),
            )
            return remainingFilters
        }
        if (genreSelected.includes(selection as string)) {
            const remainingFilters = genreSelected.filter((val) => val !== selection)
            dispatch({
                type: 'advancedFiltersApplied',
                labels: labelSelected,
                formats: formatSelected,
                countries: countrySelected,
                years: yearSelected,
                genres: remainingFilters,
                roles: roleSelected,
            })
            history.push(
                set(location, {
                    genre: genreSelected.filter((val) => val !== selection),
                    page: null,
                }),
            )
            return remainingFilters
        }
        if (roleSelected.includes(selection as string)) {
            const remainingFilters = roleSelected.filter((val) => val !== selection)
            dispatch({
                type: 'advancedFiltersApplied',
                labels: labelSelected,
                formats: formatSelected,
                countries: countrySelected,
                years: yearSelected,
                genres: genreSelected,
                roles: remainingFilters,
            })
            history.push(
                set(location, {
                    role: roleSelected.filter((val) => val !== selection),
                    page: null,
                }),
            )
            return remainingFilters
        }
        if (search === selection) {
            dispatch({
                type: 'search',
                search: '',
            })

            history.push(
                set(location, {
                    superFilter,
                    subFilter,
                    creditFilter,
                    searchParam: null,
                    page: null,
                }),
            )
        }

        return null
    }

    return (
        <div className={css.facetFilter}>
            <Progressbar loading={Boolean(loading)} aria-label={i18n._(t`Applying filters`)} />
            <div className={css.chips}>
                <Trans>Filter by</Trans>
                {chips.map(
                    (value) =>
                        value !== '' && (
                            <Chip onClick={() => chipHandler(value)} key={value}>
                                {value}
                            </Chip>
                        ),
                )}
                {search && (
                    <Chip onClick={() => chipHandler(search)} key={search}>
                        {`"${search}"`}
                    </Chip>
                )}
                {(chips.length > 0 || search) && (
                    <button className={css.clearAllButton} onClick={() => clearAll()}>
                        Clear All
                    </button>
                )}
            </div>

            <div className={classnames(css.dropdownsContainer, loading && css.loading)} ref={ref}>
                {Object.entries(data).map(([key, value], i) => (
                    <div key={key}>
                        <FilterSelectUI
                            inputValue={inputValues[i]}
                            dispatch={dispatch}
                            isOpen={isOpen}
                            isFocused={false}
                            data={value}
                            label={key}
                            setIsOpen={setIsOpen}
                            id={i}
                            labelSelected={labelSelected}
                            countrySelected={countrySelected}
                            formatSelected={formatSelected}
                            yearSelected={yearSelected}
                            genreSelected={genreSelected}
                            roleSelected={roleSelected}
                            advancedFilterSelected={advancedFilterSelected}
                            advancedFilterShowState={advancedFilterShowState}
                            ref={ref}
                        />
                    </div>
                ))}
            </div>
            <SearchBar
                dispatch={dispatch}
                search={search}
                loading={loading}
                placeholder={t(i18n)`Search Discography`}
                ariaLabel={t(i18n)`Search Discography`}
            />
        </div>
    )
}

type ListBoxProps = {
    id: number | string
    isOpen: { format: boolean; label: boolean; year: boolean }
    data:
        | {
              year: FacetObjects['year']
              format: FacetObjects['format']
              years: FacetObjects['years']
              genres: FacetObjects['genres']
              label: FacetObjects['label']
          }
        | {}
    label: string
    dispatch: Dispatcher<Actions>
    labelSelected: string[] | []
    formatSelected: string[] | []
    countrySelected: string[] | []
    yearSelected?: number[] | []
    genreSelected?: string[] | []
    roleSelected?: string[] | []
    advancedFilterSelected: { [key: string]: boolean }
    advancedFilterShowState: {
        desktop: boolean | null
        mobile: boolean
    }
}

function ListBox(props: ListBoxProps): ReactElement | null {
    const {
        id,
        isOpen,
        data,
        dispatch,
        label,
        labelSelected,
        formatSelected,
        yearSelected,
        genreSelected,
        roleSelected,
        countrySelected,
        advancedFilterSelected,
        advancedFilterShowState,
    } = props

    const totalCount = Object.values(data)
        .map((value) => value.totalCount)
        .reduce((partialSum, a) => partialSum + a, 0)

    return (
        <div
            className={css.list}
            role='listbox'
            id={`${id}-listbox`}
            aria-multiselectable
            hidden={!isOpen[label as keyof typeof isOpen]}
        >
            {Object.entries(data).map(([key, value]) => (
                <Option
                    item={key}
                    value={value}
                    dispatch={dispatch}
                    label={label}
                    labelSelected={labelSelected}
                    formatSelected={formatSelected}
                    countrySelected={countrySelected}
                    yearSelected={yearSelected}
                    genreSelected={genreSelected}
                    roleSelected={roleSelected}
                    total={totalCount}
                    id={key}
                    key={key}
                    advancedFilterSelected={advancedFilterSelected}
                    advancedFilterShowState={advancedFilterShowState}
                />
            ))}
        </div>
    )
}

export type OptionProps = {
    item: string
    value: {
        subFacet: { totalCount: number; subFacetName: string }[]
        totalCount: number
    }
    dispatch: Dispatcher<Actions>
    label: string
    labelSelected: string[] | []
    formatSelected: string[] | []
    countrySelected: string[] | []
    yearSelected?: number[] | []
    genreSelected?: string[] | []
    roleSelected?: string[] | []
    total: number
    id: string | number
    advancedFilterSelected: { [key: string]: boolean }
    advancedFilterShowState: {
        desktop: boolean | null
        mobile: boolean
    }
}

type ClickHandlerRTNType = (label: string, item: string | number) => void

// below I have disabled next-line arrow-body-style
// In this context using block statements is correct
// as we are not directly returning a value we are
// just executing multiple statements.

export const useClickHandler = ({
    dispatch,
    labelSelected,
    formatSelected,
    countrySelected,
    yearSelected,
    genreSelected,
    roleSelected,
    advancedFilterSelected,
    advancedFilterShowState,
}: OptionProps): ClickHandlerRTNType => {
    const history = useHistory()
    const location = useLocation()

    const clickHandler: ClickHandlerRTNType = (label, item) => {
        const updatedFilterSelections = { ...advancedFilterSelected }

        updatedFilterSelections[item] = !advancedFilterSelected[item]

        dispatch({
            type: 'advancedFilterSelected',
            advancedFilterSelected: updatedFilterSelections,
            advancedFilterShowState,
        })

        if (label === 'label') {
            const remainingFilters = labelSelected.filter((value: string | number) => value !== item)
            dispatch({
                type: 'advancedFiltersApplied',
                labels: updatedFilterSelections[item] ? [...remainingFilters, item as string] : remainingFilters,
                formats: formatSelected,
                countries: countrySelected,
                years: yearSelected,
                genres: genreSelected,
                roles: roleSelected,
            })
            history.push(
                set(location, {
                    label: updatedFilterSelections[item] ? [...remainingFilters, item as string] : remainingFilters,
                    page: null,
                }),
            )
        }
        if (label === 'format') {
            const remainingFilters = formatSelected.filter((value: string) => value !== item)
            dispatch({
                type: 'advancedFiltersApplied',
                formats: updatedFilterSelections[item] ? [...remainingFilters, item as string] : remainingFilters,
                labels: labelSelected,
                countries: countrySelected,
                years: yearSelected,
                genres: genreSelected,
                roles: roleSelected,
            })
            history.push(
                set(location, {
                    format: updatedFilterSelections[item] ? [...remainingFilters, item as string] : remainingFilters,
                    page: null,
                }),
            )
        }
        if (label === 'country') {
            const remainingFilters = countrySelected.filter((value: string) => value !== item)
            dispatch({
                type: 'advancedFiltersApplied',
                formats: formatSelected,
                labels: labelSelected,
                countries: updatedFilterSelections[item] ? [...remainingFilters, item as string] : remainingFilters,
                years: yearSelected,
                genres: genreSelected,
                roles: roleSelected,
            })
            history.push(
                set(location, {
                    country: updatedFilterSelections[item] ? [...remainingFilters, item as string] : remainingFilters,
                    page: null,
                }),
            )
        }
        if (label === 'year') {
            const remainingFilters = yearSelected ? yearSelected.filter((value: number) => value !== item) : []
            dispatch({
                type: 'advancedFiltersApplied',
                formats: formatSelected,
                labels: labelSelected,
                countries: countrySelected,
                years: updatedFilterSelections[item as number]
                    ? [...remainingFilters, item as number]
                    : remainingFilters,
                genres: genreSelected,
                roles: roleSelected,
            })
            history.push(
                set(location, {
                    year: updatedFilterSelections[item as number]
                        ? [...remainingFilters, item as number]
                        : remainingFilters,
                    page: null,
                }),
            )
        }
        if (label === 'genre') {
            const remainingFilters = genreSelected ? genreSelected.filter((value: string) => value !== item) : []
            dispatch({
                type: 'advancedFiltersApplied',
                formats: formatSelected,
                labels: labelSelected,
                countries: countrySelected,
                years: yearSelected,
                genres: updatedFilterSelections[item] ? [...remainingFilters, item as string] : remainingFilters,
                roles: roleSelected,
            })
            history.push(
                set(location, {
                    genre: updatedFilterSelections[item] ? [...remainingFilters, item as string] : remainingFilters,
                    page: null,
                }),
            )
        }
        if (label === 'role') {
            const remainingFilters = roleSelected ? roleSelected?.filter((value: string) => value !== item) : []
            dispatch({
                type: 'advancedFiltersApplied',
                formats: formatSelected,
                labels: labelSelected,
                countries: countrySelected,
                years: yearSelected,
                genres: genreSelected,
                roles: updatedFilterSelections[item] ? [...remainingFilters, item as string] : remainingFilters,
            })
            history.push(
                set(location, {
                    role: updatedFilterSelections[item] ? [...remainingFilters, item as string] : remainingFilters,
                    page: null,
                }),
            )
        }
    }

    return clickHandler
}

function Option(props: OptionProps): ReactElement | null {
    const { item, value, label, total, id, advancedFilterSelected } = props

    const ref = useRef<HTMLDivElement>(null)

    const clickHandler = useClickHandler(props)

    if (value.totalCount && value.totalCount === 0) {
        return null
    }

    const cssSelected = advancedFilterSelected[item]

    return (
        <div
            className={classnames(css.item, cssSelected && css.selected)}
            ref={ref}
            role='option'
            aria-selected={cssSelected}
            onClick={() => clickHandler(label, item)}
            onKeyDown={function (e) {
                if (e.key === 'Enter') {
                    clickHandler(label, item)
                }
            }}
            key={id}
            tabIndex={0}
        >
            <div className={css.itemContent}>
                <span className={classnames(css.tick, cssSelected && css.selected)} aria-hidden>
                    <Check />
                </span>
                <span className={css.value}>{item === 'NULL' ? 'Unspecified' : item}</span>
                <span className={css.count}>{value.totalCount ? value.totalCount : null}</span>
            </div>
            <span
                className={classnames(css.size)}
                aria-hidden
                style={{ width: `${(85 * value.totalCount) / total}%` }}
            />
        </div>
    )
}
