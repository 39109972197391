import { useState } from 'react'

/**
 * useId generates an id that will constant during the calling components' lifecycle.
 * This id can be used for browser features that rely on id's, like aria-controls, etc.
 * Use sparingly.
 *
 * @example
 *   const id = useId
 *   const component = (
 *      <>
 *          <button aria-controls={id}>Click me!</button>
 *          <div id={id} />
 *      </>
 *   )
 */
export function useId(): string {
    // eslint-disable-next-line react/hook-use-state
    const [id] = useState(`id${Math.round(1000000000 * Math.random())}`)
    return id
}
