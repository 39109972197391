import { ReactElement, useRef, useState, useEffect } from 'react'
import { AngleRight, CaretDown, CaretUp, Check, Close, Menu, Sliders } from '../icon'
import { Trans, t } from '@lingui/macro'
import classnames from 'classnames'
import css from './mobile.css'
import { Actions, FacetObjects, OptionProps, useClickHandler } from '.'
import { Dispatcher } from '../../add-state'
import { TabsMobile } from '../../../components/artist-filters'
import { useSticky } from '../../use-sticky'
import { SearchBar } from '../basicSearch'
import { useBlockScroll } from '../../use-block-scroll'
import { useHistory, useLocation } from 'react-router'
import { set } from '../../query'
import { useLocalisation } from '../../../lib/i18n'

type Props = {
    data:
        | {
              year: FacetObjects['year']
              format: FacetObjects['format']
              years: FacetObjects['years']
              genres: FacetObjects['genres']
              label: FacetObjects['label']
              role: FacetObjects['role']
          }
        | {}
    dispatch: Dispatcher<Actions>
    labelSelected: string[]
    formatSelected: string[]
    countrySelected: string[]
    yearSelected?: number[]
    genreSelected?: string[]
    roleSelected?: string[]
    advancedFilterSelected: { [key: string]: boolean }
    advancedFilterShowState: {
        desktop: boolean | null
        mobile: boolean
    }
    loading: boolean
    filterSelected: string[]
    toggleSidebar?: () => void
    creditName: string
    tab?: string
    inputValues: string[]
    setTab: (value: string) => void
    headerIsExpanded: boolean
    search: string
}

export function FacetFilterMobile(props: Props): ReactElement {
    const {
        data,
        labelSelected = [],
        formatSelected = [],
        countrySelected = [],
        yearSelected = [],
        genreSelected = [],
        roleSelected = [],
        advancedFilterSelected,
        dispatch,
        advancedFilterShowState,
        loading,
        filterSelected = [],
        toggleSidebar,
        creditName,
        tab,
        setTab,
        headerIsExpanded,
        search,
        inputValues,
    } = props

    const searchTermApplied = search === '' ? 0 : 1

    const totalFiltersApplied =
        [...labelSelected, ...formatSelected, ...countrySelected, ...genreSelected, ...yearSelected, ...roleSelected]
            .length + searchTermApplied

    const history = useHistory()
    const location = useLocation()
    const { i18n } = useLocalisation()

    const ref = useRef<HTMLDivElement>(null)

    const isSticky = useSticky(ref, headerIsExpanded)

    const [scrollCompleted, setScrollCompleted] = useState(false)

    useEffect(
        function () {
            let timer: number | null = null

            if (advancedFilterShowState.mobile) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                timer = window.setTimeout(() => setScrollCompleted(true), 1000)
            } else {
                setScrollCompleted(false)
            }
            return () => {
                if (timer !== null) {
                    clearTimeout(timer)
                }
            }
        },
        [advancedFilterShowState.mobile],
    )

    useBlockScroll({ block: scrollCompleted })

    const [slideOut, setSlideOut] = useState(false)

    function clearFilters() {
        for (const key in advancedFilterSelected) {
            advancedFilterSelected[key] = false
        }
        dispatch({
            type: 'advancedFiltersApplied',
            formats: [],
            labels: [],
            countries: [],
            years: [],
            genres: [],
            roles: [],
        })
        dispatch({ type: 'search', search: '' })
        history.push(
            set(location, {
                searchParam: null,
                format: null,
                role: null,
                country: null,
                genre: null,
                year: null,
                label: null,
            }),
        )
    }

    function closeModal(clearFilters: boolean): void {
        if (clearFilters) {
            for (const key in advancedFilterSelected) {
                advancedFilterSelected[key] = false
            }
            dispatch({
                type: 'advancedFiltersApplied',
                formats: [],
                labels: [],
                countries: [],
                years: [],
                genres: [],
                roles: [],
            })
            dispatch({
                type: 'search',
                search: '',
            })
            history.push(
                set(location, {
                    searchParam: null,
                    format: null,
                    role: null,
                    country: null,
                    genre: null,
                    year: null,
                    label: null,
                }),
            )
            return
        }

        setSlideOut(true)
        // allow time for the transition to finish
        setTimeout(function () {
            dispatch({
                type: 'advancedFilterSelected',
                advancedFilterSelected,
                advancedFilterShowState: { desktop: false, mobile: false },
            })
        }, 400)
    }

    return (
        <div className={css.stickyContainer}>
            <div className={isSticky ? css.sticky : ''} ref={ref}>
                {tab && <TabsMobile tab={tab} setTab={setTab} />}
                <div className={css.mobileSearchbar}>
                    {filterSelected[1] ? (
                        <span className={css.mobileReleasesPath}>
                            <button
                                onClick={() =>
                                    dispatch({
                                        type: 'sidebarFilters',
                                        filterSelected: [filterSelected[0]],
                                        initialFilter: [''],
                                    })
                                }
                            >
                                <Trans>{filterSelected[0]}</Trans>
                            </button>
                            <AngleRight aria-hidden='true' />
                            <Trans>
                                <h2>{filterSelected[1]}</h2>
                            </Trans>
                        </span>
                    ) : creditName ? (
                        <span className={css.mobileReleasesPath}>
                            <Trans>
                                <h2>Credits</h2>
                            </Trans>
                            <AngleRight aria-hidden='true' />
                            <Trans>
                                <h2>{creditName}</h2>
                            </Trans>
                        </span>
                    ) : (
                        <span className={css.mobileReleasesPath}>
                            <Trans>
                                <h2>{filterSelected[0]}</h2>
                            </Trans>
                        </span>
                    )}

                    <SearchBar
                        dispatch={dispatch}
                        search={search}
                        loading={loading}
                        placeholder={t(i18n)`Search Discography`}
                        ariaLabel={t(i18n)`Search Discography`}
                    />
                    <div className={css.buttonRow}>
                        {toggleSidebar ? (
                            <span>
                                <button className={css.mobileFiltersButton} onClick={toggleSidebar}>
                                    <Trans>Categories</Trans>
                                    <Menu aria-hidden='true' />
                                </button>
                            </span>
                        ) : (
                            <span />
                        )}
                        <span>
                            {totalFiltersApplied > 0 && (
                                <button className={css.filtersAppliedButton} onClick={() => clearFilters()}>
                                    <Trans>{totalFiltersApplied} Applied</Trans>
                                    <Close aria-hidden='true' />
                                </button>
                            )}
                            <button
                                onClick={() =>
                                    dispatch({
                                        type: 'advancedFilterSelected',
                                        advancedFilterSelected,
                                        advancedFilterShowState: {
                                            desktop: false,
                                            mobile: true,
                                        },
                                    })
                                }
                                disabled={loading}
                                className={css.mobileFiltersButton}
                            >
                                <Sliders aria-hidden='true' />
                                <Trans>Filters</Trans>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            {advancedFilterShowState.mobile && (
                <div className={classnames(css.filterBoxMobile, slideOut && css.slideOut)}>
                    <div className={css.topRow}>
                        <button className={css.close} onClick={() => closeModal(false)}>
                            <Close />
                        </button>
                        <h2>
                            <Trans>Filters</Trans>
                        </h2>
                        {totalFiltersApplied > 0 && (
                            <button className={css.clearAll} onClick={() => closeModal(true)}>
                                <Trans>Clear all</Trans>
                            </button>
                        )}
                    </div>
                    <div>
                        {Object.entries(data).map(([key, value], i) => (
                            <div key={key}>
                                <FilterSelectUIMobile
                                    heading={inputValues[i]}
                                    dispatch={dispatch}
                                    data={value}
                                    labelSelected={labelSelected}
                                    countrySelected={countrySelected}
                                    formatSelected={formatSelected}
                                    yearSelected={yearSelected}
                                    genreSelected={genreSelected}
                                    roleSelected={roleSelected}
                                    advancedFilterSelected={advancedFilterSelected}
                                    advancedFilterShowState={advancedFilterShowState}
                                    label={key}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

type FilterProps = {
    dispatch: Dispatcher<Actions>
    labelSelected: string[]
    formatSelected: string[]
    countrySelected: string[]
    yearSelected?: number[]
    genreSelected?: string[]
    roleSelected?: string[]
    advancedFilterSelected: { [key: string]: boolean }
    data: { format: FacetObjects['format']; label: FacetObjects['label'] } | {}
    heading: string
    label: string
    advancedFilterShowState: {
        desktop: boolean | null
        mobile: boolean
    }
}

function FilterSelectUIMobile(props: FilterProps): ReactElement | null {
    const {
        heading,
        data,
        label,
        dispatch,
        labelSelected,
        formatSelected,
        countrySelected,
        genreSelected,
        yearSelected,
        roleSelected,
        advancedFilterSelected,
        advancedFilterShowState,
    } = props

    const [showNumber, setShowNumber] = useState(5)

    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    if (!data) {
        return <div />
    }

    const totalCount = Object.values(data)
        .map((value) => value.totalCount)
        .reduce((partialSum, a) => partialSum + a, 0)

    const filterCountObj = {
        Format: formatSelected.length ?? 0,
        'Labels & Companies': labelSelected?.length ?? 0,
        Country: countrySelected.length ?? 0,
        Genre: genreSelected?.length ?? 0,
        Year: yearSelected?.length ?? 0,
    }

    return (
        <div className={css.filterSection}>
            <div className={css.heading}>
                <h2>
                    <Trans>{heading}</Trans>
                </h2>
                {filterCountObj[heading as keyof typeof filterCountObj] > 0 && (
                    <div className={css.circleContainer}>
                        <span className={css.circleNumber}>
                            {filterCountObj[heading as keyof typeof filterCountObj]}
                        </span>
                    </div>
                )}
            </div>
            <div className={css.mobileList}>
                {Object.entries(data)
                    .slice(0, showNumber)
                    .map(([key, value]) => (
                        <div key={key}>
                            <OptionMobile
                                id={key}
                                item={key}
                                value={value}
                                total={totalCount}
                                dispatch={dispatch}
                                labelSelected={labelSelected}
                                countrySelected={countrySelected}
                                formatSelected={formatSelected}
                                yearSelected={yearSelected}
                                genreSelected={genreSelected}
                                roleSelected={roleSelected}
                                advancedFilterSelected={advancedFilterSelected}
                                advancedFilterShowState={advancedFilterShowState}
                                label={label}
                            />
                        </div>
                    ))}
                {Object.keys(data).length > 5 && (
                    <button
                        className={css.showMore}
                        onClick={() => (showNumber === 5 ? setShowNumber(Object.keys(data).length) : setShowNumber(5))}
                    >
                        {showNumber === 5 ? <Trans>Show more</Trans> : <Trans>Show less</Trans>}
                        {showNumber === 5 ? <CaretDown aria-hidden='true' /> : <CaretUp aria-hidden='true' />}
                    </button>
                )}
            </div>
        </div>
    )
}

function OptionMobile(props: OptionProps): ReactElement | null {
    const { value, label, item, total, advancedFilterSelected, id } = props

    const clickHandler = useClickHandler(props)

    if (value.totalCount && value.totalCount === 0) {
        return null
    }

    const cssSelected = advancedFilterSelected[item]

    return (
        <div key={id}>
            <div className={css.itemContent} onClick={() => clickHandler(label, item)} role='button'>
                <span className={classnames(css.tick, cssSelected && css.selected)} aria-hidden>
                    <Check />
                </span>
                <span className={css.value}>{item}</span>
                <span className={css.count} style={{ width: `${(85 * value.totalCount) / total}%` }}>
                    {value.totalCount ? value.totalCount : null}
                </span>
            </div>
        </div>
    )
}
